<template>
  <ProductsForm
    :composed="isComposed"
    :product="product"
    :type="'create'"
    @save="createProduct"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    ProductsForm: () => import('./ProductsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isComposed: null,
    isFormValid: true,
    askToLeave: true,
    product: {
      product_name: null,
      product_reference: null,
      product_ncm: null,
      product_description: null,
      product_height: null,
      product_width: null,
      product_diameter: null,
      product_weight: null,
      product_depth: null,
      product_energy: null,
      product_keywords: null,
      product_vendors: [],
      vendor_selected: null,
      categories: [],
      segments: [],
      special_dates: [],
      product_print_height: null,
      product_print_width: null,
      product_print_measure: null,
      product_print_types: [],
      product_colors: [],
      product_images: [],
      product_videos: [],
      product_composition: {
        items: [],
        total_cost: 0,
      },
      is_new: false,
      is_on_sale: false,
      is_clearance_sale: false,
      is_temporary: false,
    },
  }),
  created() {
    this.isComposed = this.$route.params.isComposed
    this.product.isComposition = this.$route.params.isComposed
  },
  methods: {
    // validate(value) {
    //   this.isFormValid = value;
    // },

    createProduct() {
      this.askToLeave = false

      const formData = new FormData() // Criando um FormData
      const productKeys = Object.keys(this.product) // Armazenando todas as chaves do objeto de PRODUTO
      const productValues = Object.values(this.product) // Armazenando todos os valores do objeto de PRODUTO

      /*
       ** Instanciando o array de imagens do produto
       */
      var product = this.product.product_images
      var images_string = []
      /*
       ** Percorrendo o objeto de PRODUTO
       */
      for (let i = 0; i < product.length; i++) {
        /*
         ** Criando um atributo chamado "color_id" dentro do File
         ** recebido pelo parâmetro "src" do PRODUTO
         */
        let str = {
          color_id: product[i].color_id,
          name_file: `${product[i].src.name}`,
        }

        // let nameFile = "colorID"+ product[i].color_id + "_" + product[i].src.name
        let nameFile = product[i].src.name
        let newFile = new File([product[i].src], nameFile)
        // let newFile = new File([product[i].src], product[i].src.name)
        newFile.color_id = product[i].color_id

        /*
         ** Definindo os índices do array "product_images" como File
         */
        product[i] = newFile

        /*
         ** Fazendo append do novo array para o form data
         */
        formData.append('product_images[]', product[i])
        images_string.push(str)
      }

      var videos = this.product.product_videos
      var videos_string = []
      for (let i = 0; i < videos.length; i++) {
        let nameFile
        if (videos[i].id != undefined) {
          nameFile = videos[i].src
        } else {
          nameFile = videos[i].name
        }

        let str = {
          id: videos[i].id ?? null,
          name_file: nameFile,
        }

        let newFile = new File([videos[i]], nameFile)
        videos[i] = newFile

        formData.append('product_videos[]', videos[i])
        videos_string.push(str)
      }
      formData.append('image_string[]', JSON.stringify(images_string))
      formData.append('video_string[]', JSON.stringify(videos_string))

      /*
       ** Fazendo append do restante das propriedades que não são imagens
       */
      for (let i = 0; i < productKeys.length; i++) {
        if (productValues[i] !== null) {
          if (
            // productKeys[i] == 'product_categories' ||
            productKeys[i] == 'product_composition' ||
            productKeys[i] == 'vendor_selected' ||
            productKeys[i] == 'product_print_types' ||
            productKeys[i] == 'product_vendors'
          ) {
            let stringValue = JSON.stringify(productValues[i])
            formData.append(productKeys[i], stringValue)
          } else if (
            productKeys[i] != 'product_images' &&
            productKeys[i] != 'product_videos'
          ) {
            formData.append(productKeys[i], productValues[i])
          }
        }
      }

      let categories = []
      categories.push(
        ...this.product.categories,
        ...this.product.segments,
        ...this.product.special_dates
      )

      formData.append('all_product_categories', JSON.stringify(categories))

      // this.$refs.form.validate()
      if (this.isFormValid) {
        this.$api
          .post('products', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Produto cadastrado com sucesso',
              confirm: () => {
                this.$router.push({ name: 'products' })
              },
            })
          })
          .catch((error) => {
            let hasMessage =
              error?.response?.data?.message ||
              'Ops! Falha ao tentar cadastrar este produto'
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: hasMessage,
              caption: 'Verifique se os campos estão preenchidos corretamente.',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar este produto',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
